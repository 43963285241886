import React from "react";
import process from "../img/process.webp";
import process_1 from "../img/process_1.webp";
import process_2 from "../img/process_2.webp";
import process_3 from "../img/process_3.webp";
import process_4 from "../img/process_4.webp";

const Procedures = () => {
  return (
    <div>
      <img
        className="hidden mx-auto md:block w-[100%] h-auto mb-2"
        src={process}
        alt="配送情報入力用のQRコードを店舗に設置します　配送を依頼されたら、お客様のスマホからQRコードを読み取り配送先住所を入力してもらいます　お客様から商品代金と合わせて海外配送料金と配送手数料を受け取ります　商品を梱包して配送センター（東京都）へ送ってください　※海外配送用の梱包は不要です"
        loading="lazy"
        width={1024}
        height={1017}
      />
      <div className="block md:hidden">
        <img
          className="mb-4 w-[100%] h-auto"
          src={process_1}
          alt="配送情報入力用のQRコードを店舗に設置します"
          loading="lazy"
          width={696}
          height={696}
        />
        <img
          className="mb-4 w-[100%] h-auto"
          src={process_2}
          alt="配送を依頼されたら、お客様のスマホからQRコードを読み取り配送先住所を入力してもらいます"
          loading="lazy"
          width={696}
          height={696}
        />
        <img
          className="mb-4 w-[100%] h-auto"
          src={process_3}
          alt="お客様から商品代金と合わせて海外配送料金と配送手数料を受け取ります"
          loading="lazy"
          width={696}
          height={696}
        />
        <img
          className="mb-2 md:mb-0 w-[100%] h-auto"
          src={process_4}
          alt="商品を梱包して配送センター（東京都）へ送ってください　※海外配送用の梱包は不要です"
          loading="lazy"
          width={696}
          height={696}
        />
      </div>
      <p className="text-[#F6C544] font-bold text-left md:text-right text-[14px] md:text-[20px]  mb-8 md:mb-20">
        QRコードは（株）デンソーウェーブの登録商標です
      </p>
    </div>
  );
};

export default Procedures;
