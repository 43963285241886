import { Helmet } from "react-helmet-async";
import "../App.css";
import ActionButtons from "../components/ActionButtons";
import DeliveryAvailable from "../components/DeliveryAvailable";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Information, { News } from "../components/Information";
import Process from "../components/Process";
import TopMenuPc from "../components/TopMenuPc";
import TopMenuSp from "../components/TopMenuSp";
import Worry from "../components/Worry";
import Service from "../components/Service";
import Press from "../components/Press";
import Movie from "../components/Movie";
import Recommendation from "../components/Recommendation";
import TopButton from "../components/TopButton";

function Top({ news }: { news: News[] }) {
  return (
    <>
      <Helmet>
        <title>インバウンド対策のための海外配送サービスBeaver Delivery</title>
      </Helmet>
      <div className="App">
        <TopMenuSp />
        <TopMenuPc />
        <Header />
        <Movie />
        <div className="wrap max-w-[1024px] mx-0 md:mx-4 lg:mx-auto">
          <Recommendation />
          <Process />
          <Service />
          <DeliveryAvailable />
          <Worry />
          <Press />
          <Information news={news} />
          <ActionButtons />
        </div>
        <Footer />
        <TopButton />
      </div>
    </>
  );
}

export default Top;
