import React from "react";
import MoreButton from "./MoreButton";
import Infos from "./Infos";
import InformationArchive from "./InformationArchive";
import InformationDetail from "./InformationDetail";
import { Link, useLocation } from "react-router-dom";

export type News = {
  title: string;
  body: string;
  lp_slug: string;
  created_at: string;
  updated_at: string;
};

const Information = ({ news }: { news: News[] }) => {
  const location = useLocation();
  const background = location.state?.backgroundLocation;
  const base_url = process.env.REACT_APP_DIR;

  let announce = "";

  // お知らせの存在チェック
  if (news[0] == null) {
    announce = "現在お知らせがありません";
  } else {
    announce = "※最新4件を表示しています";
  }
  return (
    <div className="mb-10 md:mb-32" id="information">
      <h2 className="mb-2 md:mb-8">お知らせ</h2>
      <div className="bg-[#F6C544] w-[90%] md:w-[100%] h-fit md:h-fit mx-auto mt-3 md:mt-0 pb-5 md:pb-20 rounded-md">
        <div className="w-[90%] mx-auto pt-[1.5rem] md:pt-[2rem]">
          <p className="-translate-y-2 md:translate-y-4 text-[#000000] md:text-[#ffffff] text-left text-[16px] md:text-[24px] font-bold">
            {announce}
          </p>
        </div>
        <Infos news={news} />
      </div>
      {news.length > 4 ? (
        <button className="bg-[#ffffff] flex w-[150px] md:w-[250px] h-10 md:h-16 mx-auto mt-5 justify-center items-center rounded-[8px]">
          <Link
            className="text-[24px] md:text-[32px] font-semibold"
            to={`${base_url}/news`}
            state={{ backgroundLocation: location }}
          >
            <span className="sr-only">お知らせの続き</span>MORE
          </Link>
          <Link
            to={`${base_url}/news`}
            state={{ backgroundLocation: location }}
          >
            <MoreButton />
          </Link>
        </button>
      ) : (
        ""
      )}
      {background && (
        <>
          <InformationArchive news={news} />
          <InformationDetail news={news} />
        </>
      )}
    </div>
  );
};

export default Information;
