import React from "react";

const CloseButton = ({
  close,
}: {
  close: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <button
      onClick={() => close(false)}
      className="cursor-pointer x-button-pc z-10 ml-auto"
      aria-label="メニュー閉じるボタン"
    ></button>
  );
};

export default CloseButton;
