import React from "react";
import { Link } from "react-scroll";

const TopMenuPc = () => {
  const urlApplication = process.env.REACT_APP_CONTACT_URL + "/franchises";
  const urlTerm = "/term_of_service.html";
  return (
    <header>
      <nav className="hidden md:flex bg-[#ffffff] pb-4">
        <ul className="lg:max-w-[1400px] flex w-[90%] mx-auto mt-10 bg-[#F6C544] py-4 px-6 justify-between md:max-[1066px]:text-[min(1.8vmin,16px)] md:text-[min(2.5vw,21.5px)] font-bold gap-4">
          <li className="group">
            <button>
              サービス概要<span className="font-serif">▼</span>
            </button>
            <ul className="hidden group-hover:block absolute bg-[#ffffff] rounded-none shadow-md -translate-x-[1rem] z-40 text-left">
              <li className="pt-8 pb-2 px-6 text-shadow">
                <Link to="process" smooth={true} className="cursor-pointer">
                  配送受付フロー
                </Link>
              </li>
              <li className="py-3 pl-6 text-shadow">
                <Link to="service" smooth={true} className="cursor-pointer">
                  サービスの特徴
                </Link>
              </li>
              <li className="pt-2 pl-6 pb-8 text-shadow">
                <Link
                  to="deliveryAvailable"
                  smooth={true}
                  className="cursor-pointer"
                >
                  配送対象国
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="questions" smooth={true} className="cursor-pointer">
              よくある質問
            </Link>
          </li>
          <li>
            <Link to="press" smooth={true} className="cursor-pointer">
              メディア掲載実績
            </Link>
          </li>
          <li>
            <Link to="information" smooth={true} className="cursor-pointer">
              お知らせ
            </Link>
          </li>
          <li>
            <a href={urlTerm}>契約店規約</a>
          </li>
          <li>
            <a href={urlApplication}>お申し込みフォーム</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default TopMenuPc;
