import React from "react";

const ActionButton = ({
  content,
  color,
  textColor,
  url,
}: {
  content: string;
  color: string;
  textColor: string;
  url: string;
}) => {
  const properties: string =
    "h-[60px] md:h-[100px] lg:h-[125px] mx-auto py-4 md:py-8 lg:py-9 rounded-[5px] md:rounded-[20px] mt-5 font-bold shadow-medium " +
    { color }.color +
    " " +
    { textColor }.textColor;

  return (
    <a href={url} className="w-[50%] md:w-[30%] block mx-auto">
      <div className={properties}>
        <p className="drop-shadow-lg text-[20px] md:text-[30px]">{content}</p>
      </div>
    </a>
  );
};

export default ActionButton;
