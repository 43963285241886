import logo from "../img/beaver_icon.webp";
import parse from "html-react-parser";

const QuestionAnswer = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const htmltext =
    `<html><body><div>` + { answer }.answer + `</div></body></html>`;
  return (
    <div className="w-[100%] md:w-[100%] mx-auto mb-6 md:mb-12">
      <div className="flex">
        <div className="bg-[#F6C544] ml-auto w-[80%] md:w-[65%] rounded-[5px] md:rounded-[20px] flex items-center py-5 md:py-10 px-5 md:px-10">
          <p className="mx-auto md:text-[24px] font-bold text-white">
            {parse(question)}
          </p>
        </div>
      </div>
      <div className="-mt-0 md:-mt-[30px] lg:-mt-[80px]">
        <img
          className="w-[80px] md:w-[150px] lg:w-[234px] -translate-y-[25px] md:-translate-y-[25px] lg:-translate-y-[10px]"
          src={logo}
          alt="ロゴ"
          width={234}
          height={153}
        />
        <div className="bg-[#ffffff] mr-auto -mt-[40px] md:-mt-[50px] min-h-auto md:min-h-[10vh] rounded-[5px] md:rounded-[20px] flex items-center py-5 md:py-10 px-5 md:px-10">
          <p
            className="mx-auto md:text-[24px] font-bold"
            dangerouslySetInnerHTML={{ __html: htmltext }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswer;
