import { useRef, useEffect } from "react";
import QuestionAnswer from "./QuestionAnswer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

type QuestionAnswerItem = {
  question: string;
  answer: string;
};

const QuestionAnswers = () => {
  const questionAnswers: QuestionAnswerItem[] = [
    {
      question: "配送状やインボイス作成など<br>煩雑な手続きが不安",
      answer:
        'お客様が自分のスマートフォンから<br>直接配送先の住所を登録し<br class="md:hidden">提携している配送センターで、<br>配送状とインボイスを作成します！',
    },
    {
      question: '割れたり、壊れたりしないか<br class="md:hidden" />不安',
      answer:
        'お酒はエアパッキンに1本ずつ入れて<br>海外配送用に丁寧に梱包します。<br>お酒以外の商品は、<br class="hidden md:block" />必要に応じて<br class="md:hidden" />段ボールの外側を緩衝材で補強します。',
    },
    {
      question:
        '各国のアルコールの<br class="md:hidden" />持込制限や関税について<br>質問されても答えられない',
      answer: "早見表をご用意しています。",
    },
    {
      question:
        '購入後、お客様からの<br class="md:hidden" />問い合わせに<br>多言語で答えられない',
      answer: "配送センターが窓口になります。",
    },
  ];
  const imgRef = useRef(null);
  useEffect(() => {
    const el = imgRef.current;
    gsap.fromTo(
      el,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: el,
          start: "top 60%",
        },
      }
    );
  }, []); // []を第二引数に指定すると、初回のレンダリング時のみ動作する
  return (
    <div ref={imgRef}>
      {questionAnswers.map((questionAnswer: QuestionAnswerItem, index) => {
        return (
          <QuestionAnswer
            key={questionAnswer.question}
            question={questionAnswer.question}
            answer={questionAnswer.answer}
          />
        );
      })}
    </div>
  );
};

export default QuestionAnswers;
