import React from "react";
import logo from "../img/j&j_logo.svg";

const Footer = () => {
  const urlApplication = process.env.REACT_APP_CONTACT_URL + "/franchises";

  return (
    <footer className="mt-32 md:mt-56 bg-inherit md:bg-[#F6C544]">
      <nav className="hidden md:flex w-[80%] mx-auto">
        <ul className="flex w-[100%] my-8 justify-between md:text-[18px] lg:text-[24px] font-bold">
          <li>
            <a href={urlApplication}>申込フォーム</a>
          </li>
          <li>
            <a href="/about.html">運営会社</a>
          </li>
          <li>
            <a href="/privacy_policy.html">プライバシーポリシー</a>
          </li>
          <li>
            <a href="/term_of_service.html">契約店規約</a>
          </li>
        </ul>
      </nav>
      <img
        className="mx-auto w-[12%] md:w-[8%]"
        src={logo}
        alt="J&J"
        loading="lazy"
        width={114}
        height={113}
      />
      <p className="mt-5 pb-5 text-[12px] md:text-[16px]">
        ©️J&J Tax Free Corp.
      </p>
    </footer>
  );
};

export default Footer;
