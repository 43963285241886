import { animateScroll as scroll } from "react-scroll";
import { useEffect, useState } from "react";

function TopButton() {
  const [isButtonActive, setIsButtonActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  const scrollEvent = () => {
    const top = 100;
    let scroll = 0;
    scroll = window.scrollY;
    if (top <= scroll) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  };
  const buttonHidden = {
    opacity: 0,
    transition: "0.5s",
    pointerEvents: "none",
  };
  const buttonActive = {
    opacity: 1,
    transition: "0.5s",
  };
  const style = isButtonActive ? buttonActive : buttonHidden;
  return (
    <div
      className="bg-[#F6C544] opacity-80 fixed bottom-8 right-4 rounded-full p-3 cursor-pointer"
      style={style}
      onClick={scroll.scrollToTop}
    >
      <span>Top</span>
    </div>
  );
}

export default TopButton;
