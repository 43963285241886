import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Top from "./pages/Top";
import InformationArchive from "./components/InformationArchive";
import { useEffect, useState } from "react";
import { News } from "./components/Information";
import axios from "axios";
import InformationDetail from "./components/InformationDetail";
import { HelmetProvider } from "react-helmet-async";
import Error from "./pages/Error";
import ReactGA from "react-ga4";

function App() {
  const location = useLocation();
  const background = location.state?.backgroundLocation;
  const [news, setNews] = useState<News[]>([]);
  useEffect(() => {
    axios.get("/news.json").then((response) => {
      setNews(response.data);
    });
  }, []);

  useEffect(() => {
    if (window.location.hostname === "beaver-delivery.com") {
      ReactGA.initialize("G-H7YQDD3K49");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
    }
    // Google Analytics 測定 ID を入力して設定
  }, [location]);
  return (
    <HelmetProvider>
      <Routes location={background || location}>
        <Route index element={<Top news={news} />} />
        <Route path="news" element={<InformationArchive news={news} />} />
        <Route path="news/:slug" element={<InformationDetail news={news} />} />
        <Route path="*" element={<Error />} />
      </Routes>
      {background && (
        <Routes>
          <Route path="news" element={<InformationArchive news={news} />} />
          <Route
            path="news/:slug"
            element={<InformationDetail news={news} />}
          />
        </Routes>
      )}
    </HelmetProvider>
  );
}

export default App;
