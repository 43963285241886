import React from "react";

const ExitButton = () => {
  return (
    <>
      <button className="cursor-pointer x-button-pc z-10"></button>
    </>
  );
};

export default ExitButton;
