import React from 'react'
import ActionButton from './ActionButton'

const ActionButtons = () => {
  return (
    <div>
        <ActionButton content="お申し込み" color="bg-[#F6C544]" textColor="text-white" url="https://contact.beaver-delivery.com/franchises" />
        <ActionButton content="お問い合わせ" color="bg-[#ffffff]" textColor="text-black" url="https://j-taxfree.jp/contact.html" />
    </div>
    
  )
}

export default ActionButtons