import React, { useEffect, useRef, useState } from "react";
import { News } from "./Information";
import ExitButton from "./ExitButton";
import logo from "../img/beaver_icon.webp";
import backButtonPc from "../img/button-back_pc.svg";
import backButtonSp from "../img/button-back_sp.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";

gsap.registerPlugin(ScrollTrigger);

const InformationDetail = ({ news }: { news?: News[] }) => {
  const params = useParams();
  const location = useLocation();
  const [article, setArticle] = useState<News>();
  const base_url = process.env.REACT_APP_DIR;
  useEffect(() => {
    news?.map((content) => {
      if (params.slug === content.lp_slug) {
        setArticle(content);
      }
      return "";
    });
  }, [news, params.slug]);

  const imgRef = useRef(null);
  useEffect(() => {
    const el = imgRef.current;
    gsap.fromTo(
      el,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: el,
        },
      }
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${article?.title}`} | Beaver Delivery</title>
      </Helmet>
      <div className="bg-gray-200 w-[100vw] h-[100vh] fixed top-0 left-0 z-[3]">
        <div
          className="bg-[#F2EFE9] h-[85vh] max-w-[1020px] w-[94vw] md:w-[75vw] mx-auto mt-[7vh] rounded-lg"
          ref={imgRef}
        >
          <div className="h-[81vh] max-w-[960px] w-[88vw] md:w-[71vw] mx-auto translate-y-[2vh] rounded-lg ">
            <div className="flex w-[95%] mx-auto pt-[2vh] items-start">
              <Link to={`${base_url}/`}>
                <ExitButton />
              </Link>
              <Link
                className="z-20"
                to={`${base_url}/news`}
                state={{ backgroundLocation: location }}
              >
                <img
                  className="hidden md:block h-[75px] ml-3"
                  src={backButtonPc}
                  alt="バックボタン"
                />
                <img
                  className="block md:hidden h-[32px] ml-3"
                  src={backButtonSp}
                  alt="バックボタン"
                />
              </Link>
            </div>
            <div className="flex flex-col">
              <div className="flex mx-auto md:ml-auto md:mr-[2%] leading-4 md:leading-none text-[#ffffff] md:text-[#000000] bg-[#F6C544] md:px-0 h-[2em] w-[11em] text-[16px] md:text-[24px] justify-center items-center rounded-[8px] mt-4 md:mt-0 translate-y-0 md:-translate-y-[6vh] order-2 md:order-1">
                {article?.created_at}
              </div>
              <div className="block mx-auto mt-0 md:-mt-[3vh] order-1 md:order-2">
                <img
                  className="mx-auto z-10 relative w-[25%] md:w-[15%]"
                  src={logo}
                  alt="ロゴ"
                />
                <div className="bg-white rounded-[8px] text-[16px] md:text-[24px] w-[90%] md:w-[80%] mx-auto font-bold -mt-[4%] md:-mt-[2.5%] z-0 px-10 py-5">
                  {article?.title || ""}
                </div>
              </div>
              <div className="bg-white rounded-[8px] w-[90%] md:w-[96%] h-[38vh] md:h-[36vh] mx-auto mt-2 md:mt-[2vh] py-6 md:py-10 px-4 md:px-8 text-[16px] md:text-[24px] text-left font-bold overflow-scroll order-3">
                <p className="whitespace-pre-wrap">
                  {parse(article?.body || "")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationDetail;
