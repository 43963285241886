import React from "react";
import MoreButton from "./MoreButton";
import { Link, useLocation } from "react-router-dom";

const Info = ({
  title,
  lp_slug,
  date,
  device,
}: {
  title: string;
  lp_slug: string;
  date: string;
  device: string;
}) => {
  const classTitle = { device }.device + "-title";
  const classDate = { device }.device + "-date";
  const classMore = { device }.device + "-more";
  const location = useLocation();
  const base_url = process.env.REACT_APP_DIR;

  return (
    <Link
      to={`${base_url}/news/${lp_slug}`}
      state={{ backgroundLocation: location }}
    >
      <div className={device}>
        <p className={classTitle}>{title}</p>
        <div className="block ml-auto">
          <div className={classDate}>
            <p>{date}</p>
          </div>
          <div className={classMore}>
            <MoreButton />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Info;
