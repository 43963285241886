import React from "react";

const Menu = ({
  content,
  icon,
  color,
}: {
  content: string;
  icon: string;
  color: string;
}) => {
  const properties: string =
    "flex mt-[1vh] w-[90vw] px-[3vw] " + { color }.color;

  return (
    <div className={properties}>
      <img className="" src={icon} alt={content} />
      <p className="font-bold py-[1.75vh] pl-[3vh] text-left">{content}</p>
    </div>
  );
};

export default Menu;
