import React from "react";
import QuestionAnswers from "./QuestionAnswers";

const Worry = () => {
  return (
    <div className="w-[90%] md:w-[100%] mx-auto mb-8 md:mb-24" id="questions">
      <h2 className="mb-2 md:mb-12 relative">よくある質問</h2>
      <QuestionAnswers />
    </div>
  );
};

export default Worry;
