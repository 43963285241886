import React from "react";

const ServiceSummary = ({
  summary,
  text,
}: {
  summary?: string;
  text?: string;
}) => {
  const htmltext = `<html><body><div>` + { text }.text + `</div></body></html>`;
  return (
    <div className="text-center text-[16px] md:text-[26px] font-bold">
      <div className="bg-[#F6C544] w-[30%] mx-auto mb-2 md:mb-4 rounded-[5px] md:rounded-[17px] py-1 md:py-3">
        <p className="text-white">{summary}</p>
      </div>
      <div
        className="bg-[#ffffff] rounded-[5px] md:rounded-[17px] mb-5 md:mb-10 py-6"
        dangerouslySetInnerHTML={{ __html: htmltext }}
      ></div>
    </div>
  );
};

export default ServiceSummary;
