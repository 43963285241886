import React, { useEffect, useRef } from "react";
import Info from "./Info";
import ExitButton from "./ExitButton";
import { News } from "./Information";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet-async";
import logo from "../img/beaver_icon.webp";
gsap.registerPlugin(ScrollTrigger);

const InformationArchive = ({ news }: { news: News[] }) => {
  const imgRef = useRef(null);
  const base_url = process.env.REACT_APP_DIR;
  useEffect(() => {
    const el = imgRef.current;
    gsap.fromTo(
      el,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: el,
        },
      }
    );
  }, []);
  return (
    <>
      <Helmet>
        <title>News | Beaver Delivery</title>
      </Helmet>
      <div className="bg-gray-200 w-[100vw] h-[100vh] fixed top-0 left-0 z-[3]">
        <div
          className="bg-[#F2EFE9] h-[85vh] max-w-[1020px] w-[94vw] md:w-[75vw] mx-auto mt-[7vh] rounded-lg"
          ref={imgRef}
        >
          <div className="h-[81vh] max-w-[960px] w-[88vw] md:w-[71vw] mx-auto translate-y-[2vh] rounded-lg">
            <div className="flex w-[95%] md:h-[25vh] mx-auto pt-[2vh]">
              <Link to={`${base_url}/`}>
                <ExitButton />
              </Link>
            </div>
            <div className="block mx-auto mt-0 md:-mt-[20vh] text-center">
              <img
                className="mx-auto z-10 relative w-[15%] hidden md:block"
                src={logo}
                alt="ロゴ"
              />
              <div className="bg-inherit md:bg-white rounded-[8px] text-[20px] md:text-[24px] inline-block w-[90%] md:w-auto mx-auto font-bold -mt-[5%] md:-mt-[2.5%] z-0 px-0 md:px-10 py-5">
                お知らせアーカイブ
              </div>
            </div>
            <div className="w-[90%] h-[58vh] md:h-[50vh] top-[14vh] md:top-[27vh] left-[5%] overflow-y-auto absolute">
              <div>
                {news?.map((article, index) => {
                  return (
                    <div key={index} className="mb-10">
                      <Info
                        title={article.title}
                        date={article.created_at}
                        lp_slug={article.lp_slug}
                        device="sp-info"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationArchive;
