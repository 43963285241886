import React from "react";
import icon from "../img/beaver_icon_top.webp";
import icon_sp from "../img/beaver_icon.webp";
import logo from "../img/logo.webp";

const Header = () => {
  return (
    <div className="bg-[#ffffff] pt-14 pb-6 px-4">
      <div className="mx-auto block md:flex justify-between md:w-[90%] lg:w-[90%] lg:max-w-[1250px] h-auto">
        <div className="flex flex-col mx-auto md:mx-0 w-[96%] md:w-[60%] text-center md:text-left justify-between">
          <h1 className="text-[#0074B6] font-bold text-[14px] md:text-[min(2.3vw,21px)] lg:max-[1250px]:text-[min(2.4vw,28px)] lg:text-[min(2.4vw,32px)] md:pl-8">
            インバウンド対策のための海外配送サービス
          </h1>
          <div className="mx-auto md:mx-0 flex items-center justify-center mb-auto w-[95%]">
            <picture>
              <source
                media="(max-width:767px)"
                srcSet={icon_sp}
                width={77}
                height={50}
              />

              <img
                className="w-[80px] md:w-[100%] pt-1.5 md:pt-0 translate-y-1.5 md:translate-y-0"
                src={icon}
                alt="Beaver Deliveryロゴ"
                width={691}
                height={99}
              />
            </picture>
            <span className="text-[31px] md:hidden">
              <img src={logo} alt="Beaver Delivery" height={33} width={245} />
            </span>
          </div>
          <div className="block bg-[#F2EFE9] md:bg-inherit rounded-[6px] py-4 md:py-0 px-4 md:px-0 mb-4 md:mb-0 -mt-2 md:mt-0">
            <h2 className="text-[15px] md:max-[944px]:text-[1.8vw] md:text-[min(1.9vw,25px)] min-[1425px]:text-[min(3vw,26.5px)]  mb-8">
              海外配送サービス「Beaver Delivery」は、
              <br className="md:hidden" />
              簡単な手続きで、
              <br className="hidden md:block" />
              訪日観光客の自宅まで
              <br className="md:hidden" />
              お土産を配送できるサービスです。
            </h2>
            <h2 className="text-[15px] md:max-[944px]:text-[1.8vw] md:text-[min(1.9vw,25px)] min-[1425px]:text-[min(3vw,26.5px)] ">
              海外配送サービスの導入により、
              <br className="md:hidden" />
              手ぶら観光を推進し、
              <br className="hidden md:block" />
              商品の購入量UPの
              <br className="md:hidden" />
              お手伝いをさせていただきます。
            </h2>
          </div>
        </div>
        <div className="flex flex-col justify-end mx-auto md:mx-0 w-[96%] md:w-[40%]">
          <img
            className="w-[100%] mt-3 md:mt-12"
            src="/usage_image.webp"
            alt="Beaver Delivery利用イメージ"
            width={500}
            height={251}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
