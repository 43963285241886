import React from "react";
import RecommendationPoint from "./RecommendationPoint";

const Recommendation = () => {
  return (
    <div
      className="w-[90%] md:w-[100%] mx-auto mb-8 md:mb-24"
      id="Recommendation"
    >
      <h2 className="mb-2 md:mb-10">
        こんな商品を扱っている店舗様に
        <br className="md:hidden" />
        向いてます
      </h2>
      <div>
        <ol>
          <RecommendationPoint
            index={1}
            text="重たいまたは大きくてかさばる商品"
          />
          <RecommendationPoint
            index={2}
            text="オーダーメイドなど受け取りに時間がかかる商品"
          />
          <RecommendationPoint index={3} text="お取り寄せ商品" />
        </ol>
      </div>
    </div>
  );
};

export default Recommendation;
