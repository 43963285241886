function Movie() {
  return (
    <div className="bg-[#ffffff] pt-14 pb-6 md:py-20 mb-10 md:mb-32" id="movie">
      <div className="w-[90%] lg:w-[100%] mx-auto mb-8 md:mb-10 lg:max-w-[1024px]">
        <h2>
          BeaverDeliveryのサービス紹介は
          <br className="md:hidden" />
          こちら
        </h2>
        <div className="w-[100%] aspect-video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/2vwGVIwvVf0?si=MxbCclUt1XSunJxK"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Movie;
