import React from "react";

const DeliveryAvailable = () => {
  return (
    <div
      className="pt-10 w-[90%] md:w-[100%] mx-auto mb-10 md:mb-32"
      id="deliveryAvailable"
    >
      <h2 className="mb-4">配送対象国・地域について</h2>
      <div className="bg-[#ffffff] mt-4 md:mt-0 mb-5 md:mb-10 py-10 md:py-20 font-bold">
        <div className="bg-[#F6C544] w-[70%] md:w-[50%] mx-auto mb-4 py-4 text-[#ffffff] text-[16px] md:text-[26px] rounded-[5px]">
          <h3>アルコールの場合</h3>
        </div>
        <div className="mx-auto text-[16px] md:text-[26px] mb-10 md:mb-32">
          <table className="w-[80%] m-auto border-4 border-[#F6C544] font-bold">
            <tbody>
              <tr className="border-4 border-[#F6C544] bg-[#F2EFE9]">
                <th className="w-[50%] border-4 border-[#F6C544]">本数</th>
                <th className="w-[50%] py-2 md:py-5">国</th>
              </tr>
              <tr className="border-4 border-[#F6C544]">
                <td className="border-4 border-[#F6C544]">
                  2本<br></br>
                  <p className="mt-1 text-[10px] md:text-[16px] px-3 md:px-5">
                    ※アルコール度数24度以上の場合は1本
                  </p>
                </td>
                <td className="py-4 md:py-10">中国(＊)</td>
              </tr>
              <tr className="border-4 border-[#F6C544]">
                <td className="border-4 border-[#F6C544]">1本</td>
                <td className="py-4 md:py-10">韓国</td>
              </tr>
              <tr className="border-4 border-[#F6C544]">
                <td className="border-4 border-[#F6C544]">6本</td>
                <td className="py-4 md:py-10 px-2 md:px-5">
                  台湾・香港<span className="hidden md:inline">・</span>
                  <br className="md:hidden" />
                  シンガポール<br></br> タイ
                  <span className="hidden md:inline">・</span>
                  <br className="md:hidden" />
                  オーストラリア
                  <span className="hidden md:inline">・</span>
                  <br className="md:hidden" />
                  英国<span className="hidden md:inline"></span>
                  <br className="hidden md:block"></br>
                  <span className="md:hidden">・</span>スペイン
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-[#F6C544] w-[70%] md:w-[50%] mx-auto mb-4 py-4 text-[#ffffff] text-[16px] md:text-[26px] font-bold rounded-[5px]">
          <h3>アルコール以外の配送対象国</h3>
        </div>
        <div className="w-[80%] mx-auto text-[16px] md:text-[26px] border-4 border-[#F6C544] py-4 md:py-10">
          <p>
            中国・韓国・台湾・香港<span className="hidden md:inline">・</span>
            <br className="md:hidden" />
            シンガポール<span className="md:hidden">・</span>
            <br className="hidden md:block"></br>
            タイ<span className="hidden md:inline">・</span>
            <br className="md:hidden" />
            オーストラリア・英国・米国<br></br>
            フランス・マレーシア<span className="hidden md:inline">・</span>
            <br className="md:hidden" />
            インドネシア・フィリピン<br></br>
            ベトナム・カナダ・ドイツ<span className="hidden md:inline">・</span>
            <br className="md:hidden" />
            イタリア・スペイン
          </p>
        </div>
      </div>
      <div className="bg-[#E3E2E2] mx-auto mt-4 mb-5 rounded-xl text-left px-4 md:px-24 py-6 md:py-14 font-bold md:text-[22px]">
        <p>
          ＊中国へのアルコールの配送は
          <br className="md:hidden" />
          輸入規制により生産地が
          <br className="hidden md:block" />
          次の都道府県の場合は配送不可になります。
          <br></br>
          【対象】
          <br className="md:hidden" />
          宮城、福島、新潟、長野、東京
          <span className="hidden md:inline">、 </span>
          <br className="md:hidden" />
          茨城、群馬、栃木、埼玉、千葉
        </p>
      </div>
      <div className="bg-[#E3E2E2] mx-auto mt-4 rounded-xl text-left px-4 md:px-24 py-6 md:py-14 font-bold  md:text-[22px]">
        <p>
          配送対象外となるもの
          <br />
          【お酒の場合】<br></br>
          一升瓶、生酒等の要冷蔵の酒<br></br>
          <br></br>
          【お酒以外の商品の場合】<br></br>
          食品、相手国または配送会社の決まりにより送れないものなど
        </p>
      </div>
    </div>
  );
};

export default DeliveryAvailable;
