import React from "react";
import ServiceSummary from "./ServiceSummary";

type ServiceSummariesItem = {
  summary: string;
  text: string;
};

const ServiceSummaries = () => {
  const ServiceSummaries: ServiceSummariesItem[] = [
    {
      summary: "梱包",
      text: "酒は海外配送用の梱包は不要です<br>弊社と提携の配送センターにて<br>エアパッキンに詰め替え補強を行います<br>その他の商品は必要に応じて補強します",
    },
    {
      summary: "問い合わせ",
      text: "配送状況や破損時の顧客対応は不要です<br>弊社と提携の配送センターにて対応します",
    },
    { summary: "情報", text: "各国のアルコールの関税率一覧を提供します" },
    {
      summary: "手厚いサポート",
      text: "店舗様専用の24時間365日の<br>無償サポートデスクを完備しています",
    },
    {
      summary: "安心",
      text: "有償の運送保険もお申し込みが可能です<br>高価な商品も安心して送ることができます",
    },
  ];
  return (
    <div>
      {ServiceSummaries.map((serviceSummary: ServiceSummariesItem) => {
        return (
          <ServiceSummary
            key={serviceSummary.summary}
            summary={serviceSummary.summary}
            text={serviceSummary.text}
          />
        );
      })}
    </div>
  );
};

export default ServiceSummaries;
