import React from "react";
import press from "../img/press.webp";

function Press() {
  return (
    <div className="w-[90%] md:w-[100%] mx-auto mb-8 md:mb-10" id="press">
      <h2>メディア掲載実績</h2>
      <p className="text-[36px] -mt-4 hidden md:block font-serif">▼</p>
      <a
        target="_baink"
        rel="noopener"
        href="https://jp.sake-times.com/special/pr/pr_beaver-delivery"
        className="hover bg-[#F6C544] w-[100%] lg:h-auto gap-4 p-4 rounded-[10px] flex flex-col md:flex-row flex-wrap lg:flex-nowrap"
      >
        <div className="bg-[#ffffff] w-[100%] lg:w-[64.5%] rounded-[10px] block text-left translate-y-2 md:translate-y-0 sm:order-2 lg:order-1">
          <div className="min-[1024px]:h-[90%] w-[95%] mx-auto pt-2 text-[16px] md:text-[20px]">
            <p className="font-bold truncate ">
              SAKETIMES｜日本酒をもっと知りたくなるWEBメディア
            </p>
            <p className="font-bold text-[#2F74B9] overflow-hidden h-[43%] mt-1 md:mt-0 mb-1 line-clamp-3">
              海外配送の効率化でインバウンドの日本酒購入を促進！ー白鶴酒造も導入した新サービス「Beaver
              Delivery」の魅力｜日本酒専門WEBメディア「SAKETIMES」
            </p>
            <p className="overflow-hidden hidden md:block line-clamp-3">
              日本を訪れる海外観光客を対象に、酒蔵や酒販店から日本酒などの商品を自宅に送ることができる海外配送サービス「Beaver
              Delivery」が、2023年10月にスタートしました。
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] p-4 w-[100%] lg:w-[38%] rounded-[10px]  sm:order-1 lg:order-2">
          <img
            className="w-[100%]"
            src={press}
            alt="酒販店"
            loading="lazy"
            width={331}
            height={190}
          />
        </div>
      </a>
    </div>
  );
}

export default Press;
