import React from "react";
import Info from "./Info";
import { News } from "./Information";

const Infos = ({ news }: { news: News[] }) => {
  return (
    <div className="w-[90%] mx-auto -mt-2 md:-mt-5">
      {news?.map((article, index) => {
        if (index <= 3) {
          return (
            <div key={index} className="mt-10 md:mt-14">
              <Info
                title={article.title}
                lp_slug={article.lp_slug}
                date={article.created_at}
                device="info"
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Infos;
