import React from "react";
import Procedures from "./Procedures";

const Process = () => {
  return (
    <div className="w-[90%] md:w-[100%] mx-auto mb-8 md:mb-24" id="process">
      <h2 className="mb-2 md:mb-10">配送受付フロー</h2>
      <Procedures />
    </div>
  );
};

export default Process;
