import React, { useState } from "react";
import iconApplication from "../img/icon_application.svg";
import iconOperating from "../img/icon_operating.svg";
import iconPrivacy from "../img/icon_privacy.svg";
import iconTerm from "../img/icon_term.svg";
import iconFlow from "../img/icon_flow.svg";
import iconMedia from "../img/icon_media.svg";
import iconCharacteristics from "../img/icon_characteristics.svg";
import iconQuestion from "../img/icon_question.svg";
import iconCountry from "../img/icon_country.svg";
import Menu from "./Menu";
import logo from "../img/j&j_logo.svg";
import CloseButton from "./CloseButton";
import { Link } from "react-scroll";

type menuItem = {
  content: string;
  icon: string;
  url: string;
  type: string;
};

const TopMenuSp = () => {
  const url = process.env.REACT_APP_URL;
  const menu: menuItem[] = [
    {
      content: "配送受付フロー",
      icon: iconFlow,
      url: "process",
      type: "local",
    },
    {
      content: "サービス特徴",
      icon: iconCharacteristics,
      url: "service",
      type: "local",
    },
    {
      content: "配送国対象",
      icon: iconCountry,
      url: "deliveryAvailable",
      type: "local",
    },
    {
      content: "よくある質問",
      icon: iconQuestion,
      url: "questions",
      type: "local",
    },
    {
      content: "メディア掲載実績",
      icon: iconMedia,
      url: "press",
      type: "local",
    },
    {
      content: "申込フォーム",
      icon: iconApplication,
      url: process.env.REACT_APP_CONTACT_URL + "/franchises",
      type: "outer",
    },
    {
      content: "運営会社",
      icon: iconOperating,
      url: url + "/about.html",
      type: "outer",
    },
    {
      content: "プライバシーポリシー",
      icon: iconPrivacy,
      url: url + "/privacy_policy.html",
      type: "outer",
    },
    {
      content: "加盟店規約",
      icon: iconTerm,
      url: url + "/term_of_service.html",
      type: "outer",
    },
  ];

  const [open, setOpen] = useState(false);

  const toggleFunction = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div
        className="fixed md:hidden h-[100vh] navigation z-20"
        aria-hidden={!open}
      >
        <div className="w-[90vw] mt-4">
          <CloseButton close={toggleFunction} />
        </div>
        <p className="text-[30px]">Beaver Delivery</p>
        <nav className="h-[75vh]">
          <ul>
            {menu?.map((topic, index) => {
              let color = "";
              if (index % 2 === 0) {
                color = "bg-[#F2EFE9]";
              } else {
                color = "bg-[#F6C544]";
              }
              if (topic.type === "local") {
                return (
                  <li key={index} onClick={toggleFunction}>
                    <Link
                      to={topic.url}
                      smooth={true}
                      className="cursor-pointer"
                      onClick={toggleFunction}
                    >
                      <Menu
                        content={topic.content}
                        icon={topic.icon}
                        color={color}
                      />
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={index} onClick={toggleFunction}>
                    <a href={topic.url}>
                      <Menu
                        content={topic.content}
                        icon={topic.icon}
                        color={color}
                      />
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
        <div className="flex justify-between mb-4">
          <img className="w-[15%]" src={logo} alt="J&J" />
          <p className="mt-auto text-[20px] md:text-[30px]">
            ©️J&J Tax Free Corp.
          </p>
        </div>
      </div>
      <button
        className="hamburger-menu z-10 block md:hidden"
        onClick={toggleFunction}
        aria-label="メニューボタン"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default TopMenuSp;
